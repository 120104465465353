import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Success = () => (
  <Layout>
    <SEO title="Message Sent" />
    <h1 className="title">MESSAGE SENT</h1>
    <p className="bandDescription">Your message was successfully submitted and we'll be in touch soon.</p>
  </Layout>
)

export default Success